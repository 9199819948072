import React from "react";
import { EditOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Table,
  message,
  Modal,
  Button,
  Typography,
  Form,
  notification,
} from "antd";
import useFetchList from "../../hooks/useFetchList";
import { TableProps, Input } from "antd";
import { httpRequest } from "../../helpers/api";
import { HeaderSection, QuillEditor } from "@qlibs/react-components";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useEffect, useState } from "react";
import h2p from "html2plaintext";

const { Title, Text } = Typography;

const quillModules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["clean"],
    [
      { align: "" },
      { align: "center" },
      { align: "right" },
      { align: "justify" },
    ],
  ],
};

const quillFormats = [
  "header",
  "bold",
  "italic",
  "underline",
  "list",
  "bullet",
  "align",
];

const BasicConfig = () => {
  const [form] = Form.useForm();
  const { isLoading, data, fetchList } = useFetchList<any>({
    endpoint: "app-configs",
    initialQuery: {
      keys: encodeURIComponent("CLAIM_PHONE_NUMBER,TERMS_AND_CONDITIONS"),
    },
  });

  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = React.useState(false);
  const [generalList, setGeneralList] = React.useState<any[]>([]);
  const [terms, setTerms] = useState("");
  const [editorValue, setEditorValue] = useState("");
  const [tmpData, setTmpData] = React.useState<any>({
    key: "",
  });


  const fetch = async () => {
    try {
      const listGeneral =
        data.length > 0
          ? data
            .filter(
              (data) =>
                data.key.toLowerCase().includes("claim_phone_number") ||
                data.key.toLowerCase().includes("terms_and_conditions")
            )
            .sort((a: any, b: any) => {
              if (a.key.toLowerCase().includes("claim_phone_number"))
                return -1;
              if (b.key.toLowerCase().includes("claim_phone_number"))
                return 1;
              if (a.key.toLowerCase().includes("terms_and_conditions"))
                return 1;
              if (b.key.toLowerCase().includes("terms_and_conditions"))
                return -1;
              return 0;
            })
            .map((data: any) => {
              if (testJSON(data.value)) {
                return {
                  ...data,
                  value: JSON.parse(data.value),
                };
              } else {
                return {
                  ...data,
                };
              }
            })
          : [];

      setGeneralList(listGeneral);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    fetch();
  }, [data]);

  const handleSubmit = async () => {
    setIsLoadingUpdate(true);
    try {
      if (tmpData.key.toLowerCase().includes("claim_phone_number")) {
        const phoneNumberRegex = /^62\d{9,12}$/;
        if (!phoneNumberRegex.test(tmpData.value)) {
          notification.error({
            message: "Invalid Phone Number",
            description:
              "Please input a phone number starting with 62 and with length between 9 to 12 digits.",
          });
          setIsLoadingUpdate(false);
          return;
        }
      }
      const dataToSent = {
        value: editorValue,
      };
      await httpRequest.patch("/app-configs/" + tmpData.key, dataToSent);
      message.success("Updated successfully");

      form.resetFields();
      setIsLoadingUpdate(false);
      setIsModalVisible(false);

      // setTmpData({
      //   key: "",
      // });

      fetchList();
    } catch (error: any) {
      setIsLoadingUpdate(false);
      message.error(error.data.message);
    }
  };

  const testJSON = (text: any) => {
    if (typeof text !== "string") {
      return false;
    }
    try {
      JSON.parse(text);
      return true;
    } catch (error) {
      return false;
    }
  };

  const generalColumns = [
    {
      title: "NAME",
      dataIndex: "key",
      key: "key",
      align: "left",
      width: 180,
      render: (key: string, record: any) => {
        if (key.includes("_")) {
          return (
            <Typography.Text>
              {key
                .split("_")
                .map(
                  (value: any) =>
                    value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
                )
                .join(" ")}
            </Typography.Text>
          );
        } else {
          return (
            <Typography.Text>
              {key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()}
            </Typography.Text>
          );
        }
      },
    },
    {
      title: "VALUE",
      dataIndex: "value",
      key: "value",
      align: "left",
      width: 400,
      render: (value: any, record: any) => {
        const maxLength = 30;
        let displayText =
          value.length > maxLength
            ? value.substring(0, maxLength) + "..."
            : value;

        const style = {
          display: "-webkit-box",
          WebkitLineClamp: 3,
          WebkitBoxOrient: "vertical" as "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
        };

        return (
          <div
            style={record.key === "TERMS_AND_CONDITIONS" ? { ...style } : style}
          >
            {record.key === "TERMS_AND_CONDITIONS" ? (
              <Typography.Text>{h2p(displayText)}</Typography.Text>
            ) : (
              <Typography.Text>{displayText}</Typography.Text>
            )}
          </div>
        );
      },
    },
    {
      title: "ACTION",
      key: "action",
      align: "center",
      width: 50,
      render: (_: any, record: any) => (
        <Button
          onClick={() => {
            // console.log("record", record);
            setTmpData(record);
            // console.log("tmpData onclick", tmpData);
            setIsModalVisible(true);
          }}
          style={{ background: "transparent", border: "none" }}
          icon={<EditOutlined style={{ color: "#A5B2BD" }} />}
        ></Button>
      ),
    },
  ] as TableProps<any>["columns"];

  React.useEffect(() => {
    if (isModalVisible && tmpData.value) {
      setEditorValue(tmpData.value);
    }
  }, [isModalVisible, tmpData.value]);

  return (
    <React.Fragment>
      <Row style={{ paddingTop: 15, paddingBottom: 24 }}>
        <Col span={5}>
          <Title level={5}>Base Configuration</Title>
          <Text style={{ color: "#768499" }}>
            Change the configuration here
          </Text>
        </Col>
        <Col offset={1} span={16}>
          <Table
            rowKey="configId"
            loading={isLoading}
            columns={generalColumns}
            dataSource={generalList}
            pagination={false}
            bordered
          />
        </Col>
      </Row>
      {(() => {
        if (
          tmpData &&
          tmpData.key &&
          tmpData.key.toLowerCase().includes("claim_phone_number")
        ) {
          return (
            <Modal
              width={300}
              open={isModalVisible}
              onCancel={() => {
                setIsModalVisible(false);
              }}
              onOk={handleSubmit}
              okText="Save"
              confirmLoading={isLoadingUpdate}
            >
              <Title level={4} style={{ marginBottom: 20 }}>
                Edit{" "}
                {tmpData.key
                  .split("_")
                  .slice(1)
                  .map(
                    (value: any) =>
                      value.charAt(0).toUpperCase() +
                      value.slice(1).toLowerCase()
                  )
                  .join(" ")}
              </Title>
              <Form form={form} name="editForm" onFinish={handleSubmit}>
                <Form.Item
                  label="Value"
                  initialValue={tmpData.value}
                  rules={[
                    {
                      required: true,
                      message: "Please input the value!",
                    },
                  ]}
                >
                  <Input
                    value={tmpData.value}
                    onChange={(e) =>
                      setTmpData({ ...tmpData, value: e.target.value })
                    }
                  />
                </Form.Item>
              </Form>
            </Modal>
          );
        } else if (
          tmpData &&
          tmpData.key &&
          tmpData.key.toLowerCase().includes("terms_and_conditions")
        ) {
          return (
            <Modal
              width={700}
              visible={isModalVisible}
              onCancel={() => {
                setIsModalVisible(false);
              }}
              onOk={handleSubmit}
              okText="Save"
              confirmLoading={isLoadingUpdate}
            >
              <Title level={4} style={{ marginBottom: 20 }}>
                Edit Terms{" "}
                {tmpData.key
                  .split("_")
                  .slice(1)
                  .map(
                    (value: any) =>
                      value.charAt(0).toUpperCase() +
                      value.slice(1).toLowerCase()
                  )
                  .join(" ")}
              </Title>
              <Form form={form} name="editForm" onFinish={handleSubmit}>
                <Form.Item
                  initialValue={tmpData.value}
                  rules={[
                    {
                      required: true,
                      message: "Please input the value!",
                    },
                  ]}
                >
                  {/* <QuillEditor
                    mode="article-body"
                    value={tmpData.value}
                    onChange={(value) => {
                      setTmpData({ ...tmpData, value: value });
                    }}
                  /> */}
                  <ReactQuill
                    placeholder="Enter terms and conditions here"
                    theme="snow"
                    value={editorValue}
                    onChange={(value) => {
                      setEditorValue(value);
                    }}
                    key={tmpData.key}
                    modules={quillModules}
                    formats={quillFormats}
                  />
                </Form.Item>
              </Form>
            </Modal>
          );
        }
      })()}
    </React.Fragment>
  );
};

export default BasicConfig;
