import React from 'react';
import { Button, Card, Spin, Row, Col, Typography, Modal } from 'antd';
import { AppTable, formatCurrencyIndonesia, formatDate, IAppTableColumn, NotSet, PAGE_SIZE_OPTIONS, useFetchList } from '@qlibs/react-components';
import { useParams } from 'react-router-dom';
import { httpRequest } from '../../helpers/api';
import RowFilter from '@qlibs/react-components/dist/Table/RowFilter';
import { IPlayerHistory } from '../../types/playerHistory.type';
import HistoryExpandedRow from './components/HistoryExpandedRow';
import { priceFormat } from "../../helpers/priceFormat";
import { COLORS } from '../../assets/colors';

const { Text } = Typography;

interface ILocation {
  playerId: string;
}

const GameHistoryDetail: React.FC = () => {
  const { playerId } = useParams<keyof ILocation>() as ILocation;

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const {
    isLoading: isLoadingPlayers,
    raw,
    data: dataPlayers,
    pagination: paginationPlayers,
    handleSearch: handleSearchPlayers,
    fetchList: fetchListPlayers,
    changePage: changePagePlayers,
    changeLimit: changeLimitPlayers,
    pageQueries: pageQueriesPlayers,
  } = useFetchList<IPlayerHistory>({
    endpoint: `/players/${playerId}/histories`,
    // limit: +PAGE_SIZE_OPTIONS[0],
    limit: 999999,
    httpRequest: httpRequest as any,
    pageQuery: {
      defaultValue: { page: 1 },
    },
  });

  const handleMarkAllAsTransfered = async (playerId: any, gameId: any) => {
    try {
      setIsLoading(true);
      await httpRequest({
        method: 'POST',
        url: `/game-player-history/${playerId}/mark-all-as-transfered/${gameId}`,
      });
      setIsLoading(false);
      fetchListPlayers();
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  const columns: IAppTableColumn<IPlayerHistory>[] = [
    {
      title: 'GAME TITLE',
      dataIndex: 'gameTitle',
      key: 'gameTitle',
      width: 150,
    },
    {
      title: 'GAMEPLAY',
      key: 'maxGameplay',
      dataIndex: 'maxGameplay',
      width: 100,
    },
    {
      title: 'TRANSFERED',
      key: 'countTransfered',
      dataIndex: 'countTransfered',
      width: 110,
      render: (value, record) => {
        return {
          props: {
            style:
              value > 0
                ? {
                  backgroundColor: COLORS.bgTransfered,
                  fontWeight: 'bold',
                  fontSize: 12,
                }
                : { fontSize: 12 },
          },
          children: `${value} (${priceFormat(record.totalRewardTransferedPerPlayer)|| priceFormat(0)})`
        }
      }
    },
    {
      title: 'WAITING FOR TRANSFER',
      key: 'countWaitingForTransfer',
      dataIndex: 'countWaitingForTransfer',
      width: 175,
      render: (value, record) => {
        return {
          props: {
            style:
              value > 0
                ? {
                  backgroundColor: COLORS.bgWaiting,
                  color: COLORS.textWaiting,
                  fontWeight: 'bold',
                  fontSize: 14,
                }
                : { fontSize: 12 },
          },
          children: `${value} (${priceFormat(record.totalClaimedWaitingForTransferPerPlayer) || priceFormat(0)})`,
        }
      }
    },
    {
      title: 'NOT CLAIMED YET',
      key: 'countNotClaimedYet',
      dataIndex: 'countNotClaimedYet',
      width: 140,
      render: (value, record) => {
        return {
          children: `${value} (${priceFormat(record.totalRewardNotClaimedYetPerPlayer)|| priceFormat(0)})`
        }
      }
    },
    {
      title: 'NOT PLAYED YET',
      key: 'countNotPlayedYet',
      dataIndex: 'countNotPlayedYet',
      width: 130,
      render: (value, record) => {
        return {
          children: `${value} (${priceFormat(record.totalRewardNotPlayedPerPlayer)|| priceFormat(0)})`
        }
      }
    },
    // {
    //   title: 'TOTAL REWARD',
    //   dataIndex: 'totalRewardClaimed',
    //   key: 'totalRewardClaimed',
    //   align: 'right',
    //   type: 'price',
    //   width: 150,
    // },
    {
      title: 'ACTIONS',
      key: 'action',
      align: 'center',
      width: 200,
      render: (record) => {
        return (
          <Button
            type={record.waitingForTransfer === 0 ? 'default' : 'primary'}
            disabled={record.waitingForTransfer === 0}
            onClick={(event) => {
              event.stopPropagation();
              Modal.confirm({
                title: 'Confirmation',
                content: `Are you sure want to mark all as transfered?`,
                okText: 'Yes, sure',
                onOk: () => {
                  handleMarkAllAsTransfered(playerId, record.gameId);
                },
              });
            }}
          >
            Mark all as transfered
          </Button>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Spin spinning={isLoading}>
        <Card bordered={false} style={{ marginTop: 20 }}>
          <Row style={{ marginBottom: 15 }}>
            <Col span={4}>
              <Text style={{ fontSize: 12, color: '#768499' }}>
                Player Name
              </Text>
              <br />
              <Text strong style={{ fontSize: 16 }} ellipsis={true}>
                {raw?.summary?.playerName}
              </Text>
            </Col>

            <Col span={3}>
              <Text style={{ fontSize: 12, color: '#768499' }}>Total Game</Text>
              <br />
              <span style={{ fontSize: 14 }}>{raw.count}</span>
            </Col>

            <Col span={4}>
              <Text style={{ fontSize: 12, color: '#768499' }}>
                Last Played
              </Text>
              <br />
              {raw.summary?.lastPlayedAt ? (
                <span style={{ fontSize: 14 }}>
                  {formatDate(raw.summary?.lastPlayedAt, 'dd MMM yyyy HH:ii')}
                </span>
              ) : (
                <NotSet value="Not played yet" />
              )}
            </Col>

            <Col lg={3} md={8} xs={12}>
          <Text style={{ fontSize: 12, color: '#768499' }}>Total Transfered Reward</Text>
          <br />
          <span style={{ fontSize: 14 }}>
            {priceFormat(raw.summary?.playerTotalRewardTransfered)}
          </span>
        </Col>

        <Col lg={3} md={8} xs={12}>
          <Text style={{ fontSize: 12, color: '#768499' }}>
            Waiting for Transfer Reward
          </Text>
          <br />
          <span style={{ fontSize: 14 }}>
            {priceFormat(raw.summary?.playerTotalClaimedWaitingForTransfer)}
          </span>
        </Col>

        <Col lg={3} md={8} xs={12}>
          <Text style={{ fontSize: 12, color: '#768499' }}>Not Claimed Yet Reward</Text>
          <br />
          <span style={{ fontSize: 14 }}>
            {priceFormat(raw.summary?.playerTotalRewardNotClaimedYet)}
          </span>
        </Col>

        <Col lg={3} md={8} xs={12}>
          <Text style={{ fontSize: 12, color: '#768499' }}>
            Not Played Reward
          </Text>
          <br />
          <span style={{ fontSize: 14 }}>
            {priceFormat(raw.summary?.playerTotalRewardNotPlayed)}
          </span>
        </Col>

            {/* <Col span={4}>
              <Text style={{ fontSize: 12, color: '#768499' }}>
                Total Reward
              </Text>
              <br />
              <Text style={{ fontSize: 14 }}>
                {formatCurrencyIndonesia(raw.summary?.totalClaimedReward)}
              </Text>
            </Col> */}
          </Row>

          <RowFilter
            filterValues={{
              search: pageQueriesPlayers.search,
            }}
            filters={[
              [
                {
                  type: 'search',
                  key: 'search',
                  label: 'Search',
                  placeholder: 'Search by game name',
                  onChange: (value: any) => {
                    handleSearchPlayers(value);
                  },
                  colSpan: 24,
                },
              ],
            ]}
          />

          <AppTable
            keyId="gameId"
            columns={columns as any}
            data={dataPlayers.map((player, index) => ({
              ...player,
              key: index + 1,
            }))}
            isLoading={isLoadingPlayers}
            pagination={paginationPlayers}
            onChangeLimit={changeLimitPlayers}
            onChangePage={changePagePlayers}
            size="small"
            _table={
              {
                virtual: true,
                expandable: {
                  expandedRowRender: (record: IPlayerHistory) => (
                    <HistoryExpandedRow
                      gameplays={record.gameplays}
                      onSuccess={() => fetchListPlayers()}
                      fontSize={12}
                      minTotalReward={
                        record.game?.min_reward_per_gameplay_per_user
                      }
                      maxTotalReward={
                        record.game?.max_reward_per_gameplay_per_user
                      }
                    />
                  ),
                },
                scroll: { x: 'max-content' },
              } as any
            }
          />
        </Card>
      </Spin>
    </React.Fragment>
  );
};

export default GameHistoryDetail;

