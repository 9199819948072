import { BaseResponsePaginationProps } from './config.type';

import successIcon from '../assets/icons/playerHistory-success.svg';
import waitingIcon from '../assets/icons/playerHistory-waiting.svg';
import notClaimedIcon from '../assets/icons/playerHistory-notClaimed.svg';
import notPlayedIcon from '../assets/icons/playerHistory-notPlayed.svg';

export interface GamePlayerProps {
    playerId: string;
    name: string;
    phone: string;
    currentGameplay: number;
    maxGameplay: number;
    totalReward: number;
    totalRewardClaimed: number;
    totalRewardUnclaimed: number;

    totalRewardNotPlayed: number,
    totalRewardNotClaimedYet: number,
    totalRewardClaimedWaitingForTransfer: number,
    totalRewardTransfered: number,

    countNotPlayedYet: number;
    countNotClaimedYet: number;
    countWaitingForTransfer: number;
    countTransfered: number;

    gameplays: Array<{
        historyId: number;
        gameplay: number;
        status:
        | 'NOT_PLAYED_YET'
        | 'NOT_CLAIMED_YET'
        | 'CLAIMED_WAITING_FOR_TRANSFER'
        | 'TRANSFERED';

        currentRound: number;
        maxRound: number;

        totalReward: number;
        totalRewardClaimed: number;
        totalRewardUnclaimed: number;

        rounds: Array<{
            round: number;
            rewardGenerated: number;
            rewardClaimed: number;

            startAt?: Date | string;
        }>;

        warning: boolean;

        gameplayerId: number;

        expiredAt?: Date;
    }>;
};

// interface ErrorsImport {
//     field: string;
//     message: string;
// }

// export interface ImportGamePlayerProps {
//     name: string;
//     phone: string;
//     maxGameplay: number;
//     errors: {
//         name: ErrorsImport[],
//         phone: ErrorsImport[],
//         maxGameplay: ErrorsImport[],
//     }[];
// };


export interface FetchAllTargetsResponse extends BaseResponsePaginationProps<GamePlayerProps> {
    code: string;
    message: string;
    payload: {
        count: number
        prev: string
        next: string
        results: GamePlayerProps[]
    }
}

export const MAPPED_GAMEPLAY_STATUS: {
    [key: string]: {
        bgColor: string;
        label: string;
        icon: any;
    }
} = {
    'NOT_PLAYED_YET': {
        bgColor: '#768499',
        label: 'Not played yet',
        icon: notPlayedIcon,
    },
    'NOT_CLAIMED_YET': {
        bgColor: '#2196F3',
        label: 'Not claimed yet',
        icon: notClaimedIcon,
    },
    'CLAIMED_WAITING_FOR_TRANSFER': {
        bgColor: '#FFB300',
        label: 'Waiting for transfer',
        icon: waitingIcon,
    },
    'TRANSFERED': {
        bgColor: '#43A047',
        label: 'Transfered',
        icon: successIcon,
    }
};
