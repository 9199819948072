import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AppLayout from '../screens/layout/AppLayout';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import { Spin } from 'antd';
import Logout from '../screens/auth/Logout';
import GameHistoryDetail from '../screens/playeHistory/Detail';
import UserCreate from '../screens/user/Create';
import BasicConfig from '../screens/config';

const NotFound = React.lazy(() => import('../NotFound'));
const Preference = React.lazy(() => import('../screens/preference'));

// const Login = React.lazy(() => import('../screens/auth/NewLogin'));
const Login = React.lazy(() => import('../screens/auth/LoginGameScratch'));
const LoginAzure = React.lazy(() => import('../screens/auth/LoginAzure'));
const ForgotPassword = React.lazy(
  () => import('../screens/auth/ForgotPassword'),
);
const ResetPassword = React.lazy(() => import('../screens/auth/ResetPassword'));

const LoginGameScratch = React.lazy(() => import('../screens/auth/LoginGameScratch'));
const ForgotPasswordGameScratch = React.lazy(() => import('../screens/auth/ForgotPasswordGameScratch'));
const ResetPasswordGameScratch = React.lazy(() => import('../screens/auth/ResetPasswordGameScratch'));

const Dashboard = React.lazy(() => import('../screens/dashboard'));
const Profile = React.lazy(() => import('../screens/profile'));
const ChangeMyPassword = React.lazy(
  () => import('../screens/profile/ChangeMyPassword'),
);
const ChangePasswordUser = React.lazy(
  () => import('../screens/user/ChangePassword'),
);
const User = React.lazy(() => import('../screens/user'));
const UserEdit = React.lazy(() => import('../screens/user/Edit'));
const UserDetail = React.lazy(() => import('../screens/user/Detail'));

const Role = React.lazy(() => import('../screens/role'));
const RoleEdit = React.lazy(() => import('../screens/role/Edit'));
const RoleDetail = React.lazy(() => import('../screens/role/Detail'));

const Game = React.lazy(() => import('../screens/game'));
const GameDetail = React.lazy(() => import('../screens/game/Detail'));
const GameEdit = React.lazy(() => import('../screens/game/Edit'));

const PlayerHistory = React.lazy(() => import('../screens/playeHistory'));
const PlayerHistoryDetail = React.lazy(() => import('../screens/playeHistory/Detail'));

const RootNavigator: React.FC = () => {
  // const isLoggedIn = useIsAuthenticated();

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <PublicRoute>
              <Suspense fallback={<Spin spinning={true} />}>
                <Login />
              </Suspense>
            </PublicRoute>
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute>
              <Suspense fallback={<Spin spinning={true} />}>
                <Login />
              </Suspense>
            </PublicRoute>
          }
        />
        <Route
          path="/login-azure"
          element={
            <PublicRoute>
              <Suspense fallback={<Spin spinning={true} />}>
                <LoginAzure />
              </Suspense>
            </PublicRoute>
          }
        />
        <Route
          path="/login-game-scratch"
          element={
            <PublicRoute>
              <Suspense fallback={<Spin spinning={true} />}>
                <LoginGameScratch />
              </Suspense>
            </PublicRoute>
          }
        />
        <Route
          path="/forgot-password-gamescratch"
          element={
            <PublicRoute>
              <Suspense fallback={<Spin spinning={true} />}>
                <ForgotPasswordGameScratch />
              </Suspense>
            </PublicRoute>
          }
        />
        <Route
          path="/reset-password-gamescratch"
          element={
            <PublicRoute>
              <Suspense fallback={<Spin spinning={true} />}>
                <ResetPasswordGameScratch />
              </Suspense>
            </PublicRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <PublicRoute>
              <Suspense fallback={<Spin spinning={true} />}>
                <ForgotPassword />
              </Suspense>
            </PublicRoute>
          }
        />
        <Route
          path="/reset-password/:sessionId"
          element={
            <PublicRoute>
              <Suspense fallback={<Spin spinning={true} />}>
                <ResetPassword />
              </Suspense>
            </PublicRoute>
          }
        />

        <Route path="*" element={<NotFound />} />

        <Route element={<AppLayout />}>
          <Route
            path="/dashboard"
            element={
              <PrivateRoute loginPath="/login">
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute loginPath="/login">
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path="/change-password"
            element={
              <PrivateRoute loginPath="/login">
                <ChangeMyPassword />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <PrivateRoute loginPath="/login">
                <User userType="admin" />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/add"
            element={
              <PrivateRoute loginPath="/login">
                <UserCreate userType="admin" />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/:userId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <UserEdit userType="admin" />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/:userId"
            element={
              <PrivateRoute loginPath="/login">
                <UserDetail userType="admin" />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/:userId/change-password"
            element={
              <PrivateRoute loginPath="/login">
                <ChangePasswordUser />
              </PrivateRoute>
            }
          />
          <Route
            path="/customer"
            element={
              <PrivateRoute loginPath="/login">
                <User userType="customer" />
              </PrivateRoute>
            }
          />
          <Route
            path="/customer/add"
            element={
              <PrivateRoute loginPath="/login">
                <UserCreate userType="customer" />
              </PrivateRoute>
            }
          />
          <Route
            path="/customer/:userId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <UserEdit userType="customer" />
              </PrivateRoute>
            }
          />
          <Route
            path="/customer/:userId"
            element={
              <PrivateRoute loginPath="/login">
                <UserDetail userType="customer" />
              </PrivateRoute>
            }
          />
          <Route
            path="/customer/:userId/change-password"
            element={
              <PrivateRoute loginPath="/login">
                <ChangePasswordUser />
              </PrivateRoute>
            }
          />
          <Route
            path="/basic-configurations"
            element={
              <PrivateRoute loginPath="/login">
                <BasicConfig />
              </PrivateRoute>
            }
          />
          <Route
            path="/role"
            element={
              <PrivateRoute loginPath="/login">
                <Role />
              </PrivateRoute>
            }
          />
          <Route
            path="/role/add"
            element={
              <PrivateRoute loginPath="/login">
                <RoleEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/role/:roleId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <RoleEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/role/:roleId"
            element={
              <PrivateRoute loginPath="/login">
                <RoleDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/game"
            element={
              <PrivateRoute loginPath="/login">
                <Game />
              </PrivateRoute>
            }
          />
          <Route
            path="/game/add"
            element={
              <PrivateRoute loginPath="/login">
                <GameEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/game/:gameId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <GameEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/game/:gameId"
            element={
              <PrivateRoute loginPath="/login">
                <GameDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/player-history"
            element={
              <PrivateRoute loginPath="/login">
                <PlayerHistory />
              </PrivateRoute>
            }
          />
          <Route
            path="/player-history/:playerId"
            element={
              <PrivateRoute loginPath="/login">
                <GameHistoryDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/preference"
            element={
              <PrivateRoute loginPath="/login">
                <Preference />
              </PrivateRoute>
            }
          />
          <Route
            path="/base-config"
            element={
              <PrivateRoute loginPath="/login">
                <BasicConfig />
              </PrivateRoute>
            }
          />
          <Route
            path="/logout"
            element={
              <PublicRoute>
                <Logout />
              </PublicRoute>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
export default RootNavigator;
