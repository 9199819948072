import { formatCurrencyIndonesia, AppTable, formatDate, NotSet, generateFormRules, getErrorMessage } from '@qlibs/react-components';
import { Collapse, Row, Col, Button, Modal, message, Tooltip, Typography, Divider, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react'
import { MAPPED_GAMEPLAY_STATUS } from '../../../types/playerGame';
import { IGameplay } from '../../../types/playerHistory.type';
import Text from 'antd/lib/typography/Text';
import { httpRequest } from '../../../helpers/api';
import { InfoCircleOutlined } from '@ant-design/icons';
import { WarningOutlined } from '@ant-design/icons';
import { IconWarning } from '../../../assets/icons';
import { COLORS } from '../../../assets/colors';
import { EditOutlined } from '@ant-design/icons';
import moment from 'moment';

type Props = {
  gameplays: IGameplay[];
  onSuccess: () => void;

  fontSize?: number;
  minTotalReward: number;
  maxTotalReward: number;

  name?: string;

  // setOpenExpiredModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function HistoryExpandedRow(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [openExpiredDateModal, setOpenExpiredDateModal] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [currentGameplay, setCurrentGameplay] = useState<IGameplay>();
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [expiredNow, setExpiredNow] = useState<string>();
  const [formExpired, setFormExpired] = useState<string>();
  // const [expiredBoolean, setExpiredBoolean] = useState<boolean>(false);
  // const [currentExpiredModalType, setCurrentExpiredModalType] = useState<'Add' | 'Edit'>('Add');

  // const [gameplayData, setGameplayData] = useState<IGameplay>();

  const handleMarkAsTransfered = async (id: number, gameplay: number) => {
    try {
      setIsLoading(true);
      const res = await httpRequest.post(`/game-player-history/${id}/mark-as-transfered`);
      console.log('res: ' + JSON.stringify(res.data.payload));
      setIsLoading(false);
      // fetchListPlayers();
      props.onSuccess();
      message.success(`Success mark as transfered "gameplay ${gameplay}"`);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const renderTooltipRewardGenerated = (gameplay: IGameplay) => {
    if (gameplay.status === 'CLAIMED_WAITING_FOR_TRANSFER' || gameplay.status === 'TRANSFERED' || gameplay.status === 'NOT_CLAIMED_YET') {
      return <></>
    }
    let tooltipMessage;
    let isOk = true;
    const totalRewardGenerated = gameplay.rounds.reduce((acc, curr) => acc + curr.rewardGenerated, 0);

    if (props.minTotalReward <= totalRewardGenerated && totalRewardGenerated <= props.maxTotalReward) {
      // normal
      tooltipMessage =
        'Player will get ' + formatCurrencyIndonesia(totalRewardGenerated);
    } else {
      isOk = false;
      tooltipMessage = "Player will get " + formatCurrencyIndonesia(totalRewardGenerated) + ' (OUT OF RANGE)';
    }

    return (
      <Tooltip title={tooltipMessage} color={isOk ? "green" : "red"} style={{ width: 200 }}>
        <InfoCircleOutlined style={{ color: isOk ? "green" : "red", marginLeft: 5 }} />
      </Tooltip>
    )
  }

  const handleSubmit = async (
    values: {
      expiredAt: Date,
      expiredMode?: boolean
    },
    beforeCurrentExpired?: Date
  ) => {
    try {
      setIsLoading(true);

      console.log(JSON.stringify(values));

      console.log('currentISO: ' + new Date(values.expiredAt).toISOString());

      const data = {
        gameplayerId: currentGameplay?.gameplayerId,
        gameplay: currentGameplay?.gameplay,
        expiredAt: new Date(values.expiredAt).toISOString()
      };

      console.log('expiredDate: ' + values.expiredAt);
      console.log('beforeCurrentExpired: ' + beforeCurrentExpired);
      // console.log('expiredMode: ' + expiredBoolean.valueOf());

      const today = new Date();
      const expired = new Date(values.expiredAt);

      console.log('today: ' + today);

      console.log('logic1: ' + (!beforeCurrentExpired && values.expiredAt).valueOf())

      console.log('logic2: ' + (beforeCurrentExpired && values.expiredAt <= new Date() && values.expiredAt <= new Date(beforeCurrentExpired))?.valueOf())

      if (!beforeCurrentExpired && expired <= today && !values.expiredMode) {
        throw new Error('Expired time must be after today');
      } else if (beforeCurrentExpired && (expired <= today || expired <= new Date(beforeCurrentExpired))) {
        throw new Error('Expired time must be after today and previous gameplay expired time');
      }
      else {
        await httpRequest.post(`/games/gameplays`, data);
        message.success('Success set expired date');
        setIsLoading(false);

        if (props.onSuccess) {
          props.onSuccess();
          form.resetFields();
        }
        form.resetFields();
        // setExpiredBoolean(false);
        setOpenExpiredDateModal(false);
      }
    } catch (error) {
      setIsLoading(false);
      message.error(getErrorMessage(error));
    }
  }

  return (
    <Collapse>
      {props.gameplays.map((gameplay, index: number) => {
        return (
          <Collapse.Panel
            key={index + 1}
            header={
              <Row>
                <Col span={24}>
                  <Row>
                    <Col span={2} style={{ textAlign: 'left' }}>
                      <Text style={{ fontSize: props.fontSize }}>
                        Gameplay :{' '}
                      </Text>
                      <Text style={{ fontSize: props.fontSize }} strong>
                        {gameplay.gameplay}
                      </Text>
                    </Col>
                    <Col span={20} style={{ textAlign: 'right' }}>
                      <Row gutter={50}>
                        <Col
                          span={6}
                          style={{
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            paddingLeft: 50,
                          }}
                        >
                          <Text style={{ fontSize: props.fontSize }}>
                            Status :{' '}
                          </Text>
                          <span
                            style={{
                              backgroundColor:
                                MAPPED_GAMEPLAY_STATUS[gameplay.status]
                                  ?.bgColor,
                              padding: '1px 3px',
                              margin: '0px 8px',
                              borderRadius: '100px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <img
                              src={
                                MAPPED_GAMEPLAY_STATUS[gameplay.status]?.icon
                              }
                              alt="icon"
                              style={{
                                width: '14px',
                                height: '14px',
                              }}
                            />
                            <Text
                              style={{ fontSize: props.fontSize }}
                              strong
                              className="text-white px-2"
                            >
                              {MAPPED_GAMEPLAY_STATUS[gameplay.status]?.label}
                            </Text>
                          </span>
                        </Col>
                        <Col span={5}
                          style={{
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            paddingLeft: 35,
                          }}>

                          {/* {gameplay.expiredAt && */}
                          {(gameplay.status !== 'TRANSFERED' && gameplay.status !== 'CLAIMED_WAITING_FOR_TRANSFER') &&
                            <>
                              <Text
                                style={{
                                  fontSize: props.fontSize,
                                }}>
                                Expired At :{' '}
                              </Text>
                              <Text
                                style={{
                                  fontSize: props.fontSize,
                                  flex: 2,
                                  color:
                                    gameplay.expiredAt &&
                                      new Date(gameplay.expiredAt) <= new Date() &&
                                      (gameplay.status === 'NOT_PLAYED_YET' || gameplay.status === 'NOT_CLAIMED_YET')
                                      ? 'red' : 'black'
                                }}>
                                {gameplay.expiredAt ? formatDate(gameplay.expiredAt, 'dd MMM yyyy HH:mm') : ''}
                              </Text>
                              <EditOutlined
                                hidden={
                                  props.gameplays &&
                                    // (gameplay.status !== 'TRANSFERED' && gameplay.status !== 'CLAIMED_WAITING_FOR_TRANSFER') &&
                                    ((index === 0 && (!gameplay.expiredAt || (gameplay.expiredAt && new Date(gameplay.expiredAt) > new Date()))) ||
                                      (index > 0 && (props.gameplays[index - 1].expiredAt || props.gameplays[index - 1].status === 'CLAIMED_WAITING_FOR_TRANSFER' || props.gameplays[index - 1].status === 'TRANSFERED') &&
                                        (!gameplay.expiredAt || (gameplay.expiredAt && new Date(gameplay.expiredAt) > new Date()))))
                                    // (gameplay.expiredAt && new Date(gameplay.expiredAt) >= new Date()))
                                    // ((index > 0 && props.gameplays[index - 1].expiredAt) || (index === 0 && !gameplay.expiredAt)) 
                                    ? false : true}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setCurrentGameplay(gameplay);
                                  if (gameplay.expiredAt) {
                                    setFormExpired(new Date(gameplay.expiredAt).toISOString());
                                  }
                                  // setCurrentExpiredModalType('Edit');
                                  setOpenExpiredDateModal(true);
                                }}
                              />
                            </>
                          }

                        </Col>
                        <Col
                          span={4}
                          style={{
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Text style={{ fontSize: props.fontSize }}>
                            Round :{' '}
                          </Text>
                          <Text style={{ fontSize: props.fontSize }} strong>
                            {' '}
                            {gameplay.currentRound || 0}/{gameplay.maxRound}{' '}
                          </Text>
                        </Col>
                        <Col
                          span={4}
                          style={{
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Text style={{ fontSize: props.fontSize }}>
                            Reward :{' '}
                          </Text>
                          <Text style={{ fontSize: props.fontSize }} strong>
                            {formatCurrencyIndonesia(
                              gameplay.totalRewardClaimed
                            )}
                            {renderTooltipRewardGenerated(gameplay)}
                          </Text>
                        </Col>
                        <Col span={5} style={{ textAlign: 'center' }}>

                          <Button
                            type={
                              gameplay.status ===
                                'CLAIMED_WAITING_FOR_TRANSFER'
                                ? 'primary'
                                : 'default'
                            }
                            disabled={
                              gameplay.status ===
                                'CLAIMED_WAITING_FOR_TRANSFER'
                                ? false
                                : true
                            }
                            onClick={(event) => {
                              event.stopPropagation();

                              Modal.confirm({
                                title: 'Confirmation',
                                content: `Are you sure want to mark "gameplay ${gameplay.gameplay}" as transfered?`,
                                okText: 'Yes, sure',
                                onOk: () => {
                                  handleMarkAsTransfered(
                                    gameplay.historyId,
                                    gameplay.gameplay
                                  );
                                },
                              });
                            }}
                          >
                            Transfer
                          </Button>
                          {gameplay.status === 'TRANSFERED' &&
                            <Row style={{ textAlign: 'center', marginTop: 3, justifyContent: 'center' }}>
                              <span style={{
                                color: 'darkgrey',
                                fontStyle: 'italic',
                                fontSize: 11,
                              }}><InfoCircleOutlined style={{ marginRight: 3 }} />Transfered at {formatDate(gameplay.transferAt, 'dd MMM yyyy HH:mm')}</span>
                            </Row>
                          }

                        </Col>
                        {/* {(gameplay.totalReward < props.minTotalReward || gameplay.totalReward > props.maxTotalReward) &&
                          gameplay.status === 'NOT_PLAYED_YET' && */}
                        {gameplay.warning &&
                          <Col
                            span={1}
                            style={{
                              textAlign: 'center',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <WarningOutlined style={{
                              color: 'red',
                            }} onClick={(event) => {
                              event.stopPropagation();

                              Modal.confirm({
                                title: 'Warning',
                                content: `There is a problem with the total reward, please regenerate reward!`,
                                okText: 'Regenerate Reward',
                                onOk: () => { },
                              });
                            }} />
                          </Col>
                        }

                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Modal
                  title={`${props.name} - Gameplay ${currentGameplay?.gameplay}`}
                  open={openExpiredDateModal}
                  okText="Save"
                  onOk={(event) => {
                    event.stopPropagation();
                    form.submit();
                  }}
                  onCancel={(event) => {
                    event.stopPropagation();
                    setOpenExpiredDateModal(false);
                    // props.onCancel?.();
                    // form.resetFields();
                  }}
                  destroyOnClose={true}
                  footer={[
                    <Button
                      // key="back"
                      onClick={(event) => {
                        event.stopPropagation();
                        const expiredDate = new Date().toISOString();
                        console.log('expiredDate: ' + expiredDate);
                        // setExpiredNow(new Date().toISOString())
                        Modal.confirm({
                          title: 'Confirmation',
                          content: `Are you sure want to set expired for gameplay - ${currentGameplay?.gameplay}"?`,
                          okText: 'Yes, sure',
                          onOk: () => {
                            // setExpiredBoolean(true);
                            setFormExpired(expiredDate);
                            handleSubmit({
                              expiredAt: new Date(expiredDate),
                              expiredMode: true
                            })
                          },
                        });

                      }}>
                      Set as expired
                    </Button>,
                    <Button
                      key="back"
                      onClick={(event) => {
                        event.stopPropagation();
                        setOpenExpiredDateModal(false);
                        // props.onCancel?.();
                        // form.resetFields();
                      }}>
                      Cancel
                    </Button>,
                    <Button
                      key="submit"
                      type="primary"
                      disabled={isDisable || !formExpired}
                      loading={isLoading}
                      onClick={(event) => {
                        event.stopPropagation();
                        // form.submit();
                        console.log('indexOnClick: ' + index);
                        console.log('props gameplay', props.gameplays[index]);
                        console.log('props gameplay ' + props.gameplays[index - 1]);
                        if (formExpired) {
                          handleSubmit({
                            expiredAt: new Date(formExpired),
                          },
                            index > 0 && props.gameplays[index - 1].expiredAt ? props.gameplays[index - 1].expiredAt : undefined
                          )
                        }
                      }}>
                      Save
                    </Button>,
                  ]}
                >
                  {/* <Form
                    form={form}
                    layout="vertical"
                    onFinish={(values) => {
                      handleSubmit({
                        expiredAt: values.expiredAt
                      },
                        index > 0 && props.gameplays[index - 1].expiredAt ? props.gameplays[index - 1].expiredAt : undefined
                      )
                    }}
                    autoComplete="off"
                    preserve={false}
                    initialValues={{
                      expiredAt: currentGameplay?.expiredAt ? moment(currentGameplay.expiredAt).format('YYYY-MM-DDTHH:mm') : undefined,
                      // gameplayerId: currentGameplay?.gameplayerId,
                      // gameplay: currentGameplay?.gameplay
                    }}
                  >
                    <Form.Item
                      name="expiredAt"
                      label="Expired at"
                      rules={generateFormRules('Expired at', ['required'])}
                    >
                      <Input onClick={(event) => event.stopPropagation()} name='expiredAt' type='datetime-local' onChange={(e) => {
                        const expired = e.target.value;
                        console.log('expired: ' + expired);
                        console.log('index: ' + index);
                        if (index > 0) {
                          // console.log('index: ' + index);
                          if (props.gameplays[index - 1] && props.gameplays[index - 1].expiredAt) {
                            const beforeCurrentExpired = props.gameplays[index - 1].expiredAt ? props.gameplays[index - 1].expiredAt : undefined;
                            console.log('before expired: ' + beforeCurrentExpired);
                            if (beforeCurrentExpired) {
                              const logic = new Date(expired) <= new Date(beforeCurrentExpired);
                              console.log('before expired logic: ' + logic.valueOf());
                              setIsDisable(logic || new Date(e.target.value) <= new Date() ? true : false);
                            }
                          }
                        } else {
                          setIsDisable(new Date(expired) <= new Date() ? true : false);
                        }
                      }}
                      />
                    </Form.Item>
                  </Form> */}
                  <Input onClick={(event) => event.stopPropagation()} name='expiredAt' type='datetime-local' onChange={(e) => {
                    const expired = e.target.value;
                    console.log('expired: ' + expired);
                    console.log('index: ' + index);
                    setFormExpired(expired);
                    // if (index > 0) {
                    //   // console.log('index: ' + index);
                    //   if (props.gameplays[index - 1] && props.gameplays[index - 1].expiredAt) {
                    //     const beforeCurrentExpired = props.gameplays[index - 1].expiredAt ? props.gameplays[index - 1].expiredAt : undefined;
                    //     console.log('before expired: ' + beforeCurrentExpired);
                    //     if (beforeCurrentExpired) {
                    //       const logic = new Date(expired) <= new Date(beforeCurrentExpired);
                    //       console.log('before expired logic: ' + logic.valueOf());
                    //       if (logic || new Date(e.target.value) <= new Date()) {
                    //         setIsDisable(true);
                    //       } else {
                    //         setIsDisable(false);
                    //         setFormExpired(new Date(expired).toISOString());
                    //       }
                    //       // setIsDisable(logic || new Date(e.target.value) <= new Date() ? true : false);
                    //     } else {
                    //       setIsDisable(false);
                    //       setFormExpired(new Date(expired).toISOString());
                    //     }
                    //   }
                    // } else {
                    //   if (new Date(expired) <= new Date()) {
                    //     setIsDisable(true);
                    //   } else {
                    //     setIsDisable(false);
                    //     setFormExpired(new Date(expired).toISOString());
                    //   }
                    // }
                  }}
                    value={
                      // currentGameplay?.expiredAt ? moment(currentGameplay.expiredAt).format('YYYY-MM-DDTHH:mm') : 
                      formExpired ? moment(formExpired).format('YYYY-MM-DDTHH:mm') : undefined}
                  />
                </Modal>
              </Row>
            }
          >
            <AppTable
              keyId="round"
              columns={[
                {
                  title: 'ROUND',
                  dataIndex: 'round',
                  key: 'round',
                  width: '25%',
                },
                {
                  title: 'REWARD',
                  dataIndex: 'rewardClaimed',
                  key: 'rewardClaimed',
                  render: (value, record) => {
                    if (record.startAt) {
                      return (
                        <span>
                          {formatCurrencyIndonesia(record.rewardClaimed)}
                          &nbsp;
                          <span
                            style={{
                              color: 'darkgrey',
                              fontStyle: 'italic',
                              fontSize: 11,
                            }}
                          >
                            played at{' '}
                            {formatDate(record.startAt, 'dd MMM yyyy HH:ii')}
                          </span>
                        </span>
                      );
                    } else {
                      return (
                        <NotSet
                          value={
                            'Not played yet --> will get ' +
                            formatCurrencyIndonesia(record.rewardGenerated)
                          }
                        />
                      );
                    }
                  },
                },
                // {
                //   title: 'REWARD GENERATED',
                //   dataIndex: 'rewardGenerated',
                //   key: 'rewardGenerated',
                //   type: 'price',
                // },
              ]}
              data={gameplay.rounds}
              pagination={false}
            />
          </Collapse.Panel>


        );
      })}
    </Collapse>
  );
}