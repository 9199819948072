import { HomeOutlined, FileTextOutlined, TableOutlined, TagOutlined, PictureOutlined, InfoCircleOutlined, EnvironmentOutlined, BellOutlined, CalendarOutlined, UsergroupDeleteOutlined, UserOutlined, PlayCircleOutlined, MonitorOutlined, FireOutlined, TeamOutlined, SettingOutlined } from '@ant-design/icons';

type IMenu = {
  type?: 'group' | 'submenu';
  key?: string;
  label: string;
  icon?: any;
  permission?: boolean | string[];
  description?: string;

  children?: IMenu[];
}
export const MENUS: IMenu[] = [
  {
    type: 'group',
    label: 'GAME',
    children: [
      {
        // permission: isUserHasPermission(['CATEGORY.LIST', 'PRODUCT_CATEGORY.LIST']),
        key: '/categories',
        label: 'Category',
        icon: <TagOutlined />,
      },
      {
        permission: ['GAME.LIST'],
        key: '/game',
        label: 'Game',
        icon: <HomeOutlined />
      },
      {
        permission: ['GAME.LIST'],
        key: '/player-history',
        label: 'Player History',
        icon: <TeamOutlined />,
      },
      {
        permission: ['APP_CONFIG.LIST'],
        key: '/base-config',
        label: 'Config',
        icon: <SettingOutlined />,
      },
    ],
  },
  {
    type: 'group',
    label: 'USERS',
    children: [
      {
        permission: ['USER.LIST'],
        key: '/admin',
        label: 'Admin',
        icon: <UsergroupDeleteOutlined />,
      },
      // {
      //   permission: ['USER.LIST'],
      //   key: '/customer',
      //   label: 'Customer',
      //   icon: <UsergroupDeleteOutlined />,
      // },
      // {
      //   permission: ['ROLE.LIST'],
      //   key: '/role',
      //   label: 'Role & Permission',
      //   icon: <UserOutlined />,
      // },
    ],
  },
];

export const getFlatMenus = (menus: IMenu[]) => {
  return menus.reduce((acc, curr) => {
    if (curr.children) {
      curr.children.forEach((child: IMenu) => {
        acc.push(child);

        if (child.children) {
          child.children.forEach((child2: IMenu) => {
            acc.push(child2);
          });
        }
      });
    }
    acc.push(curr);
    return acc;
  }, [] as any[]).filter((item: any) => item.key).filter((item: any) => item.type !== 'group');
};

export const MENUS_FLAT = getFlatMenus(MENUS)
console.info('menus_flat', MENUS_FLAT)

export const getMenu = () => {
  return MENUS_FLAT.find((item: any) => item.key === window?.location?.pathname);
}